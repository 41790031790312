.default {
  background: #f3f4f6;
}

.modified {
  background: #fdf6b2;
}

td:has(.range-table-cell) {
  vertical-align: top;
}
