th::before {
  background: none !important;
}

.ant-table-thead .ant-table-cell {
  background: #f1f5fa !important;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500 !important;
  color: #667085 !important;
  font-family: 'Sofia Pro';
}

.ant-table-tbody .ant-table-cell {
  line-height: 20px;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #fd7149 !important;
  border-color: #fd7149 !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #fd7149 !important;
}
:where(.css-dev-only-do-not-override-1ck3jst).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #fd7149 !important;
}
