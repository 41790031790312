.filter-select-icon {
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: #d0d5dd; /* #FD7149; */
  font-size: 12px;
  line-height: 18px;
  border-radius: 10px;
  color: #667085; /* #fff; */
}

.filter-item {
  width: 272px;
  height: 48px;
  border-bottom: 1px solid #f3f4f6;
  border-top: 1px solid #f3f4f6;
  background: #fff;
  color: var(--text-header-h-1-n-900, #101828);
  display: inline-flex;
  padding: 14px 16px 14px 24px;
  align-items: center;
}
.filter-item > .txt-span {
  /* Text Style - Sofia Pro/Body/Regular */
  font-family: Sofia Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  width: 93%;
  text-transform: capitalize;
}
.filter-item-content {
  padding: 16px;
  max-height: 230px;
  display: list-item;
  overflow: auto;
}
.filter-item-content::-webkit-scrollbar {
  display: none;
}

.filter-item-content > [data-testid='yb-core-checkbox'] {
  padding: 7px 0px 7px 0px;
}
