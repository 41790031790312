.action {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 6px;
  border: 1px solid #e3e8ef;
}

.test {
  .action-button {
    visibility: hidden;
  }
}

.test:hover {
  .action-button {
    visibility: visible;
  }
}

/* .full-border-left{
  border-left: 2px solid #EEF2F6;
}


.half-a-border-on-left {
  border-left: 2px solid #EEF2F6;
  border-image: linear-gradient(to bottom, #EEF2F6 50%, transparent 50%) 1 100%;
} */
