.translation-text {
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
}

.dropdown {
  padding: 5px 50px 5px 5px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  color: #575757;
  outline: none;
}

.header-container {
  padding: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: space-between;
}
