body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.ant-message {
  z-index: 9999; /* Adjust this value to be higher than the default z-index */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Sofia Pro font family definitions */

@font-face {
  font-family: 'Sofia Pro';
  src:
    local('SofiaPro-Bold'),
    url('../assets/fonts/SofiaPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src:
    local('SofiaPro-Medium'),
    url('../assets/fonts/SofiaPro-Medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

div {
  scrollbar-width: thin;
  scrollbar-color: #dedede transparent;
}
/* Add other font styles and weights as needed */
/* @font-face rules for 'Black', 'Blackitalic', 'ExtraLight', 'ExtraLightItalic', etc. */
